<template>
  <div>
    <div class="columns">
    <b-field label="Payment Plan ID" class="column is-half">
        <b-input
          v-model="query.paymentPlanId"
          @input="onSearch"
          expanded
          placeholder="Search input"
          type="search"
          icon="magnify"
        >
        </b-input>
    </b-field>
    </div>
    <div class="columns">
      <b-field label="Filters:" class="column is-half">
        <b-field label="Status" class="column is-2">
          <b-select v-model="query.status" @input="retrievePaymentData" placeholder="Search">
              <option value="">Any</option>
              <option value="successful">Successful</option>
              <option value="failed">Failed</option>
          </b-select>
        </b-field>
        <div class="column is-10" style="display:flex">
          <b-field class="createdAt-dates" label="From date">
            <b-datetimepicker
                @input="retrievePaymentData"
                v-model="query.createdAtStart"
                placeholder="From"
                editable
                :max-datetime="query.createdAtEnd">
            </b-datetimepicker>
          </b-field>
          <b-field class="createdAt-dates" label="From date">
            <b-datetimepicker
                @input="retrievePaymentData"
                v-model="query.createdAtEnd"
                placeholder="To"
                editable
                :min-datetime="query.createdAtStart">
            </b-datetimepicker>
          </b-field>
        </div>
      </b-field>
    </div>
    <b-table
      :data="auditPaymentRequests"
      @select="selected"
      hoverable
      selectable
      :loading="loading"
      class="table-row"
      backend-sorting
      @sort="onSort"
    >
      <b-table-column sortable field="createdAt" label="Created At " v-slot="props">
        {{ props.row.createdAt }}
      </b-table-column>
      <b-table-column field="status" label="Status" v-slot="props">
         <span class="tag" :class="props.row.status === 'failed' ? 'is-danger': 'is-success'">
          {{ props.row.status }}
        </span>
      </b-table-column>
      <b-table-column field="paymentPlanId" label="Payment Plan ID" v-slot="props">
        {{ props.row.paymentPlanId }}
      </b-table-column>
      <b-table-column field="finalStage" label="Final Stage" v-slot="props">
        {{ props.row.finalStage }}
      </b-table-column>
    </b-table>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" ref="infiniteLoader">
      <div slot="no-more"><br />Displaying all audit requests</div>
      <div slot="no-results">No audit requests found</div>
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import debounce from 'lodash/debounce';

export default {
  name: 'AuditRequestList',
  components: { InfiniteLoading },
  props: {
    tenantCode: String,
  },
  data() {
    return {
      infiniteId: 1,
      auditPaymentRequests: [],
      cursor: null,
      loading: true,
      query: {
        pageSize: 50,
        order: 'desc',
        createdAtStart: null,
        createdAtEnd: null,
        status: '',
        paymentPlanId: null,
      },
      dataService: null,
      typeAheadEvent: null,

    };
  },
  created() {
    this.dataService = this.$dataService(this.tenantCode);
    this.typeAheadEvent = debounce(this.retrievePaymentData, 100);
    this.retrievePaymentData();
  },
  methods: {
    async onSort(_, order) {
      this.query.order = order;
      await this.retrievePaymentData();
    },
    onSearch() {
      this.typeAheadEvent();
    },
    selected(item) {
      this.$router.push({ name: 'auditRequestOverview', params: { paymentPlanId: item.paymentPlanId } });
    },
    async infiniteHandler($state) /* istanbul ignore next */ {
      if (!this.cursor) {
        $state.complete();
        return;
      }

      const { data } = await this.dataService.auditRequests.list({ cursor: this.cursor });
      this.auditPaymentRequests = this.auditPaymentRequests.concat(data.data);
      this.cursor = data.cursor;
      $state.loaded();
    },
    async retrievePaymentData() {
      this.loading = true;
      this.auditPaymentRequests = [];

      const query = Object
        .entries(this.query)
        .reduce((prev, [key, value]) => {
          if (value) return { ...prev, [key]: value };
          return prev;
        }, { });

      const { data } = await this.dataService.auditRequests.list(query);

      this.auditPaymentRequests = data.data;
      this.cursor = data.cursor;
      this.infiniteId += 1;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.createdAt-dates {
  flex:1;
  padding: 0 1% 0 1%
}
</style>
