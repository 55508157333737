<template>
  <div class="main-view">
    <audit-request-list :tenant-code="tenant" />
  </div>
</template>

<script>
import AuditRequestList from '@/components/auditRequests/AuditRequestList.vue';

export default {
  name: 'AuditRequestListView',
  components: {
    AuditRequestList,
  },
  computed: {
    tenant() {
      return this.$route.params.tenant;
    },
  },
};
</script>
